<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Default box -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="mb-0">Casos Soporte</h5>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active">Casos Soporte</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-info"
                          title="Actualizar"
                          @click="getIndex()"
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                        <button
                          v-if="
                            $store.getters.can('admin.casosMesaAyuda.create')
                          "
                          type="button"
                          class="btn btn-primary"
                          data-toggle="modal"
                          title="Crear caso"
                          data-target="#modal-form-casosSoporte"
                          @click="abrirModal('Crear', null)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          data-toggle="modal"
                          data-target="#modal_casos_soporte_export"
                          @click="limpiarModalExport('CasosSoporteExport')"
                        >
                          <i class="far fa-file-excel"></i>
                        </button>
                        <a
                          type="button"
                          class="btn btn-warning"
                          title="Crear grupo de autorización"
                          href="/CasosSoporteAutorizaciones"
                        >
                          <i class="fas fa-users-cog"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table
                    style="font-size: 0.85em"
                    class="
                      table table-bordered table-striped table-hover table-sm
                      text-nowrap
                    "
                  >
                    <thead class="text-center">
                      <tr>
                        <th>Id</th>
                        <th>
                          Asunto
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.asunto"
                            @change="getIndex()"
                          >
                            <option value="">Todos...</option>
                            <option
                              v-for="asunto in listasForms.asuntos"
                              :key="asunto.numeracion"
                              :value="asunto.numeracion"
                            >
                              {{ asunto.descripcion }}
                            </option>
                          </select>
                        </th>
                        <th>Descripción</th>
                        <th>
                          Vía de Comunicación
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.via_comunicacion"
                            @change="getIndex()"
                          >
                            <option value="">Todos...</option>
                            <option
                              v-for="via_comunicacion in listasForms.vias_comunicacion"
                              :key="via_comunicacion.numeracion"
                              :value="via_comunicacion.numeracion"
                            >
                              {{ via_comunicacion.descripcion }}
                            </option>
                          </select>
                        </th>
                        <th>
                          Línea de Negocio
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.linea_negocio"
                            @change="getIndex()"
                          >
                            <option value="">Todos...</option>
                            <option
                              v-for="linea_negocio in listasForms.lineas_negocio"
                              :key="linea_negocio.id"
                              :value="linea_negocio.id"
                            >
                              {{ linea_negocio.nombre }}
                            </option>
                          </select>
                        </th>
                        <th>
                          Usuario
                          <v-select
                            class="form-control form-control-sm p-0"
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="usuario"
                            placeholder="Usuario"
                            label="name"
                            :options="listasForms.usuarios"
                            @input="selectUser()"
                          >
                          </v-select>
                        </th>
                        <th>
                          Usuario Respuesta
                          <v-select
                            class="form-control form-control-sm p-0"
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="usuario_respuesta"
                            placeholder="Usuario Respuesta"
                            label="name"
                            :options="listasForms.usuarios"
                            @input="selectUserRespuesta()"
                          >
                          </v-select>
                        </th>
                        <th>
                          Tipo de Caso
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.tipo_caso"
                            @change="getIndex()"
                          >
                            <option value="">Todos...</option>
                            <option value="1">Usuario</option>
                            <option value="2">Mesa de ayuda</option>
                          </select>
                        </th>
                        <th>Respuesta</th>
                        <th>
                          Fecha Solicitado
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_solicitado"
                            @change="getIndex()"
                          />
                        </th>
                        <th>
                          Fecha Respuesta
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_respuesta"
                            @change="getIndex()"
                          />
                        </th>
                        <th>Tiempo</th>
                        <th>
                          Estado
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.estado"
                            @change="getIndex()"
                          >
                            <option value="">Todos...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </th>
                        <th>Archivo Adjunto</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="caso in casos.data" :key="caso.id">
                        <td>{{ caso.id }}</td>
                        <td>{{ caso.nAsunto }}</td>
                        <td class="text-wrap text-center">
                          <div v-if="caso.descripcion">
                            <button
                              v-if="
                                $store.getters.can('admin.casosMesaAyuda.show')
                              "
                              type="button"
                              class="btn btn-sm alert-default-success"
                              data-toggle="modal"
                              data-target="#modal-text"
                              @click="
                                modalText(
                                  'Leer',
                                  'Descripción',
                                  caso.descripcion
                                )
                              "
                            >
                              <i class="fas fa-question-circle"></i>
                            </button>
                          </div>
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              caso.via_comunicacion == 1
                                ? 'bg-navy'
                                : caso.via_comunicacion == 2
                                ? 'bg-info'
                                : caso.via_comunicacion == 3
                                ? 'bg-pink'
                                : caso.via_comunicacion == 4
                                ? 'bg-lime'
                                : caso.via_comunicacion == 5
                                ? 'bg-indigo'
                                : 'bg-secondary'
                            "
                          >
                            {{ caso.nViaComunicacion }}
                          </span>
                        </td>
                        <td>{{ caso.linea_negocio.nombre }}</td>
                        <td v-if="caso.casos_soporte_users.length > 0">
                          <div
                            v-for="users in caso.casos_soporte_users"
                            :key="users.id"
                          >
                            <span class="badge badge-secondary">{{
                              users.name
                            }}</span>
                          </div>
                        </td>
                        <td v-else>
                          <span class="badge badge-secondary">{{
                            caso.usuario ? caso.usuario.name : caso.user
                          }}</span>
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              caso.usuario_respuesta ? 'bg-navy' : 'bg-red'
                            "
                          >
                            {{
                              caso.usuario_respuesta
                                ? caso.usuario_respuesta.name
                                : "Sin Respuesta"
                            }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="caso.tipo_caso == 1 ? 'bg-navy' : 'bg-info'"
                          >
                            {{
                              caso.tipo_caso == 1 ? "Usuario" : "Mesa de ayuda"
                            }}
                          </span>
                        </td>
                        <td class="text-wrap text-center">
                          <div v-if="caso.respuesta">
                            <button
                              v-if="
                                $store.getters.can('admin.casosMesaAyuda.show')
                              "
                              type="button"
                              class="btn btn-sm alert-default-info"
                              data-toggle="modal"
                              data-target="#modal-text"
                              @click="
                                modalText('Leer', 'Respuesta', caso.respuesta)
                              "
                            >
                              <i class="fas fa-check-double"></i>
                            </button>
                          </div>
                          <div v-if="caso.estado == 1">
                            <button
                              v-if="
                                $store.getters.can('admin.casosMesaAyuda.show')
                              "
                              type="button"
                              class="btn btn-sm alert-default-warning"
                              data-toggle="modal"
                              data-target="#modal-text"
                              @click="
                                modalText('Agregar', 'Respuesta', caso.id)
                              "
                            >
                              <i class="fa fa-question"></i>
                            </button>
                          </div>
                        </td>
                        <td class="text-center">{{ caso.fecha_solicitado }}</td>
                        <td class="text-center">{{ caso.fecha_respuesta }}</td>
                        <td class="text-center">{{ caso.tiempo }}</td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              caso.estado == 1
                                ? 'bg-warning'
                                : caso.estado == 2
                                ? 'bg-info'
                                : caso.estado == 3
                                ? 'bg-lime'
                                : caso.estado == 4
                                ? 'bg-navy'
                                : caso.estado == 5
                                ? 'bg-danger'
                                : caso.estado == 6
                                ? 'bg-teal'
                                : caso.estado == 7
                                ? 'bg-success'
                                : ''
                            "
                          >
                            {{ caso.nEstado }}
                          </span>
                        </td>
                        <td class="text-center">
                          <a
                            :href="uri_docs + caso.link_archivo"
                            target="_blank"
                            v-if="caso.link_archivo"
                            ><i class="fas fa-file-image btn-sm bg-blue"></i
                          ></a>
                        </td>
                        <td style="width: 50px">
                          <div class="btn-group float-right">
                            <button
                              v-if="
                                $store.getters.can('admin.casosMesaAyuda.edit')
                              "
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-toggle="modal"
                              data-target="#modal-form-casosSoporte"
                              @click="abrirModal('Editar', caso.id)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-warning"
                              data-toggle="modal"
                              data-target="#modal-grupos-aut"
                              v-show="
                                $store.getters.can(
                                  'admin.casosMesaAyuda.responder'
                                ) &&
                                  !caso.codigo_verificacion &&
                                  [1, 3, 4, 5].includes(caso.estado)
                              "
                              @click="abrirGrupo(caso.id)"
                            >
                              <i class="fas fa-users"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-info"
                              v-show="
                                $store.getters.can(
                                  'admin.casosMesaAyuda.responder'
                                ) &&
                                  !caso.codigo_verificacion &&
                                  [1, 3, 4, 5].includes(caso.estado)
                              "
                              @click="casoDesarrollo(caso.id)"
                            >
                              <i class="fas fa-laptop-code"></i>
                            </button>
                            <button
                              v-show="
                                $store.getters.can(
                                  'admin.casosMesaAyuda.delete'
                                ) && [1, 3, 4, 5].includes(caso.estado)
                              "
                              type="button"
                              class="btn btn-sm btn-danger"
                              @click="destroy(caso.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="casos.total">
                    <p>
                      Mostrando del <b>{{ casos.from }}</b> al
                      <b>{{ casos.to }}</b> de un total:
                      <b>{{ casos.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="casos"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
            <CasosSoporteExport ref="casosSoporteExport" />
          </section>
        </div>
        <!-- <CasosSoporteAutorizaciones ref="CasosSoporteAutorizaciones" /> -->
      </div>
      <!-- Modal Crear o Editar Caso Soporte-->
      <div class="modal fade" id="modal-form-casosSoporte">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <div class="row w-100">
                <div class="col-md-2">
                  <h4 class="modal-title text-white">{{ modal.title }}</h4>
                </div>
                <div class="col-md-8">
                  <div class="text-center justify-content-center">
                    <h5 id="temp" class="text-white"></h5>
                  </div>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeModal1"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
                <div class="form-group col-md-3">
                  <label>Vía de Comunicación</label>
                  <select
                    class="form-control form-control-sm"
                    :class="
                      $v.objCaso.via_comunicacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="!this.bloq"
                    v-model="objCaso.via_comunicacion"
                  >
                    <option value="" disabled selected>Seleccione...</option>
                    <option
                      v-for="via_comunicacion in listasForms.vias_comunicacion"
                      :key="via_comunicacion.numeracion"
                      :value="via_comunicacion.numeracion"
                    >
                      {{ via_comunicacion.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="linea_negocio_id">Línea de Negocio</label>
                  <select
                    id="linea_negocio_id"
                    class="form-control form-control-sm"
                    :class="
                      $v.objCaso.linea_negocio_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="!this.bloq"
                    v-model="objCaso.linea_negocio_id"
                  >
                    <option value="" disabled selected>Seleccione...</option>
                    <option
                      v-for="linea_negocio in listasForms.lineas_negocio"
                      :key="linea_negocio.id"
                      :value="linea_negocio.id"
                    >
                      {{ linea_negocio.nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="unidad_medida">Tipo de Caso</label>
                  <select
                    id="tipo_caso"
                    class="form-control form-control-sm"
                    :class="
                      $v.objCaso.tipo_caso.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    v-model="objCaso.tipo_caso"
                    @change="chageTipoCaso"
                    :disabled="
                      !$store.getters.can('admin.casosMesaAyuda.edit') ||
                        !this.bloq
                    "
                  >
                    <option value="" disabled selected>Seleccione...</option>
                    <option value="1">Usuario</option>
                    <option value="2">Mesa de ayuda</option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <div class="icheck-primary d-inline">
                    <input
                      type="checkbox"
                      id="usuario"
                      v-model="chk_usuario"
                      :disabled="!this.bloq"
                      @click="cambiaTipoUsuario"
                      v-if="objCaso.tipo_caso == 2"
                    />
                    <label for="usuario">Usuario</label>
                  </div>
                  <div class="form-group d-flex flex-row" v-if="chk_usuario">
                    <input
                      type="text"
                      class="form-control form-control-sm mt-2 mr-2"
                      :class="
                        $v.objCaso.user.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="!this.bloq"
                      placeholder="Usuario"
                      v-model="objCaso.user"
                    />
                    <input
                      type="email"
                      class="form-control form-control-sm mt-2"
                      placeholder="Correo"
                      :disabled="!this.bloq"
                      v-model="objCaso.correo"
                    />
                  </div>
                  <v-select
                    v-if="!chk_usuario"
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="users"
                    placeholder="Usuario"
                    label="name"
                    :options="listasForms.usuarios"
                    :disabled="objCaso.tipo_caso == 1 && !this.bloq"
                    multiple
                  >
                  </v-select>
                </div>
                <div class="form-group col-md-6">
                  <label for="linea_negocio_id">Asunto</label>
                  <select
                    id="linea_negocio_id"
                    class="form-control form-control-sm"
                    :class="
                      $v.objCaso.asunto.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    v-model="objCaso.asunto"
                    :disabled="!this.bloq"
                  >
                    <option value="" disabled selected>Seleccione...</option>
                    <option
                      v-for="asunto in listasForms.asuntos"
                      :key="asunto.numeracion"
                      :value="asunto.numeracion"
                    >
                      {{ asunto.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="nombre">Descripción</label>
                  <textarea
                    v-model="objCaso.descripcion"
                    cols="30"
                    rows="5"
                    class="form-control form-control-sm"
                    :class="
                      $v.objCaso.descripcion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="!this.bloq"
                  ></textarea>
                </div>
                <div
                  v-if="$store.getters.can('admin.casosMesaAyuda.responder')"
                  class="form-group col-md-6"
                >
                  <label for="nombre">Respuesta</label>
                  <textarea
                    v-model="objCaso.respuesta"
                    cols="30"
                    rows="5"
                    class="form-control form-control-sm"
                    :disabled="!this.bloq"
                  ></textarea>
                </div>

                <div class="form-group col-md-6">
                  <label for="imagen">Adjunto</label><br />
                  <div class="form-group">
                    <input
                      type="file"
                      class="form-control-file"
                      accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                      @change="obtenerArchivo"
                    />
                  </div>
                  <figure class="form-group" v-if="miniLogo">
                    <div class="button-container">
                      <img
                        :src="miniLogo"
                        alt="Logo"
                        width="236"
                        height="125"
                        class="rounded mx-auto d-block"
                      />
                      <a
                        @click="destroyArchivo()"
                        :disabled="!this.bloq"
                        id="BotonEliminar"
                        v-if="objCaso.link_archivo"
                        href="#"
                        >Eliminar <i class="fas fa-window-close"></i
                      ></a>
                    </div>
                  </figure>
                  <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3" v-if="archivoValido">
                      <button
                        type="button"
                        @click="saveArchivo()"
                        class="btn btn-success"
                        data-toggle="tooltip"
                        data-html="true"
                        title="Guardar Archivo"
                        :disabled="!this.bloq"
                      >
                        <i class="fa fa-upload"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.objCaso.$invalid"
                :disabled="!this.bloq"
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
          <!-- /.card -->
        </div>
      </div>

      <!-- Modal Textos -->
      <div class="modal fade" id="modal-text">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">{{ modal.title }}</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeModal2"
                id="close-modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
                <!-- <div v-html="text"></div> -->
                <div class="form-group col-md-10">
                  <textarea
                    v-if="modal.accion == 'Leer'"
                    v-model="text"
                    cols="30"
                    rows="5"
                    class="form-control form-control-sm"
                  ></textarea>
                  <textarea
                    v-if="modal.accion == 'Agregar'"
                    v-model="objCaso.respuesta"
                    cols="30"
                    rows="5"
                    class="form-control form-control-sm"
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              v-if="
                $store.getters.can('admin.casosMesaAyuda.responder') &&
                  modal.accion == 'Agregar'
              "
              class="modal-footer justify-content-between"
            >
              <button type="button" class="btn btn-primary" @click="save()">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>

      <!---- Modal Grupos Autorización ---->
      <div class="modal fade" id="modal-grupos-aut">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">
                Asignación Grupo Autorización
              </h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeModalGrupo"
                id="closeModalGrupo"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row justify-content-center">
                <div class="form-group col-md-6">
                  <label for="empresa">Grupo</label>
                  <v-select
                    :class="[$store.getters.getDarkMode ? 'dark-vselect' : '']"
                    v-model="grupo"
                    placeholder="Nombre"
                    label="nombre"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.grupos"
                    :input="selectGrupo()"
                  ></v-select>
                </div>
              </div>

              <div v-if="grupo">
                <table
                  class="
                  table table-bordered table-striped table-hover table-sm mb-0        
                "
                  v-if="
                    grupo.det_grupo_casos_auto &&
                      grupo.det_grupo_casos_auto.length > 0
                  "
                >
                  <thead class="thead-dark">
                    <tr>
                      <th class="text-center">Orden</th>
                      <th class="text-center">Usuario</th>
                      <th class="text-center">Email</th>
                      <th class="text-center">Celular</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(grup, indice) in grupo.det_grupo_casos_auto"
                      :key="grup.id"
                    >
                      <td class="text-center">
                        <span> {{ indice + 1 }}</span>
                      </td>
                      <td>
                        <span> {{ grup.usuario.name }} </span>
                      </td>
                      <td>
                        <span> {{ grup.usuario.email }} </span>
                      </td>
                      <td class="text-center">
                        <span> {{ grup.usuario.cel }} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  class="
                  table table-bordered table-striped table-hover table-sm mb-0
                "
                  v-else
                >
                  <div class="small-box bg-info" style="min-height: 100px">
                    <div class="inner">
                      <h3>No Hay Usuarios Asociados Al Grupo</h3>
                    </div>
                    <div class="icon">
                      <i class="far fa-eye-slash"></i>
                    </div>
                  </div>
                </table>
              </div>
            </div>
            <div class="modal-footer" v-if="grupo">
              <button
                v-if="
                  grupo.det_grupo_casos_auto &&
                    grupo.det_grupo_casos_auto.length > 0
                "
                type="button"
                class="btn btn-md bg-success"
                @click="saveGrupo()"
              >
                <i class="fas fa-check"></i>&nbsp;
                <span><small>Guardar</small></span>
              </button>
              <button
                type="button"
                class="btn btn-md bg-secondary"
                data-dismiss="modal"
              >
                <i class="fas fa-ban"></i>&nbsp;
                <span><small>Cerrar</small></span>
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from "moment";
import { required, email } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../components/Loading";
import CasosSoporteExport from "./CasosSoporteExport";
/* import CasosSoporteAutorizaciones from "./CasosSoporteAutorizaciones"; */

export default {
  name: "CasosSoporteIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    CasosSoporteExport,
    /*     CasosSoporteAutorizaciones, */
  },

  data() {
    return {
      cargando: false,
      miniLogo: null,
      archivoValido: false,
      adjunto: null,
      grupo: {},
      bloq: true,
      filtros: {
        asunto: null,
        linea_negocio: null,
        usuario_id: null,
        descripcion: null,
        via_comunicacion: null,
        usuario_respuesta_id: null,
        tipo_caso: null,
        respuesta: null,
        estado: null,
      },
      text: null,
      casos: {},
      users: [],
      objCaso: {
        fecha_respuesta: null,
        respuesta: null,
      },
      form: {
        caso_id: null,
        ga_casos_soporte_id: null,
      },
      chk_usuario: false,
      usuario: [],
      usuario_respuesta: [],
      tiempo: {
        minutos: 0,
        segundos: 0,
      },
      user: {
        id: null,
        name: null,
      },
      listasForms: {
        usuarios: [],
        asuntos: [],
        vias_comunicacion: [],
        estados: [],
        grupos: [],
        rutas: [],
      },
      modal: {
        title: "",
        accion: "",
      },

      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    if (this.chk_usuario) {
      return {
        objCaso: {
          user: {
            required,
          },
          asunto: {
            required,
          },
          descripcion: {
            required,
          },
          via_comunicacion: {
            required,
          },
          linea_negocio_id: {
            required,
          },
          tipo_caso: {
            required,
          },
        },
      };
    } else {
      return {
        objCaso: {
          asunto: {
            required,
          },
          descripcion: {
            required,
          },
          via_comunicacion: {
            required,
          },
          linea_negocio_id: {
            required,
          },
          tipo_caso: {
            required,
          },
        },
      };
    }
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/admin/casosSoporte?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.casos = response.data;
          this.cargando = false;
        });
    },

    getUser() {
      this.cargando = true;
      axios.get("/api/user").then((response) => {
        this.user.id = response.data.id;
        this.user.name = response.data.name;
        this.cargando = false;
      });
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    async getUsuarios() {
      await axios.get("/api/admin/usuarios/lista").then((response) => {
        this.listasForms.usuarios = response.data;
      });
    },

    async buscarGrupo() {
      let me = this;
      me.listasForms.grupos = [];

      let url = "api/admin/casosSoporteAutorizaciones/lista";
      await axios
        .get(url)
        .then(function(response) {
          me.listasForms.grupos = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectGrupo() {
      this.form.ga_casos_soporte_id = null;
      if (this.grupo) {
        this.form.ga_casos_soporte_id = this.grupo.id;
      }
    },

    selectUser() {
      this.filtros.usuario_id = null;
      if (this.usuario) {
        this.filtros.usuario_id = this.usuario.id;
      }
      this.getIndex();
    },

    selectUserRespuesta() {
      this.filtros.usuario_respuesta_id = null;
      if (this.usuario_respuesta) {
        this.filtros.usuario_respuesta_id = this.usuario_respuesta.id;
      }
      this.getIndex();
    },

    getAsunto() {
      axios.get("/api/lista/162").then((response) => {
        this.listasForms.asuntos = response.data;
      });
    },

    getViaComunicacion() {
      axios.get("/api/lista/161").then((response) => {
        this.listasForms.vias_comunicacion = response.data;
      });
    },

    getEstado() {
      axios.get("/api/lista/160").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async abrirModal(accion, idCaso) {
      this.limpiarModal();
      let estado = 1;
      this.modal.accion = accion;
      this.modal.title = accion + " Caso";
      if (accion == "Editar") {
        await this.show(idCaso);
        await this.cambiaTipoUsuario();
        if (this.objCaso.link_archivo) {
          this.miniLogo = this.uri_docs + this.objCaso.link_archivo;
        }
        this.bloq = true;
        if (
          this.objCaso.estado == 2 ||
          this.objCaso.estado == 7 ||
          this.objCaso.estado == 5
        ) {
          this.bloq = false;
        }
        setInterval(this.iniciarTemporalizador, 10);
      } else {
        this.bloq = true;
        this.objCaso = {
          id: null,
          user: null,
          user_id: null,
          correo: null,
          respuesta: null,
          estado: estado,
        };
        this.users = [];
        if (!this.$store.getters.can("admin.casosMesaAyuda.edit")) {
          this.objCaso.tipo_caso = 1;
          this.chageTipoCaso();
        }
        this.objCaso.fecha_solicitado = null;
        this.objCaso.fecha_solicitado = moment().format("YYYY-MM-DD HH:mm:ss");
        setInterval(this.iniciarTemporalizador, 10);
      }
    },

    limpiarModal() {
      this.miniLogo = null;
      this.objCaso = {
        respuesta: "",
        fecha_respuesta: null,
        fecha_solicitado: null,
        user_id: null,
        user: null,
        correo: null,
        descripcion: null,
        link_archivo: "",
      };
    },

    limpiarGrupo(idCaso) {
      this.grupo = {};
      this.form = {
        caso_id: idCaso,
        ga_casos_soporte_id: null,
      };
    },

    iniciarTemporalizador() {
      this.tiempo.temp = document.getElementById("temp");
      this.tiempo.actual = moment();
      this.tiempo.respuesta = moment(this.objCaso.fecha_respuesta);
      this.tiempo.solicitado = moment(this.objCaso.fecha_solicitado);
      this.tiempo.promedio = this.tiempo.actual.diff(this.tiempo.solicitado);
      if (this.objCaso.fecha_respuesta && this.objCaso.fecha_solicitado) {
        this.tiempo.promedio = this.tiempo.respuesta.diff(
          this.tiempo.solicitado
        );
      }

      function menorDiez(e) {
        return (e < 10 ? "0" : "") + e;
      }

      this.tiempo.ms = this.tiempo.promedio % 1000;
      this.tiempo.promedio = (this.tiempo.promedio - this.tiempo.ms) / 1000;
      this.tiempo.segundos = this.tiempo.promedio % 60;
      this.tiempo.promedio = (this.tiempo.promedio - this.tiempo.segundos) / 60;
      this.tiempo.minutos = this.tiempo.promedio % 60;
      this.tiempo.horas = (this.tiempo.promedio - this.tiempo.minutos) / 60;
      this.tiempo.temp.innerHTML =
        menorDiez(this.tiempo.horas) +
        ":" +
        menorDiez(this.tiempo.minutos) +
        ":" +
        menorDiez(this.tiempo.segundos);
      if (this.objCaso.fecha_respuesta) {
        clearInterval(this.iniciarTemporalizador);
      }
    },

    modalText(accion, descripcion, valor) {
      this.modal.accion = accion;
      this.modal.title = accion + " " + descripcion;
      if (valor) {
        this.text = valor;
      }
      if (accion == "Agregar") {
        this.show(valor);
      }
    },

    async cambiaTipoUsuario() {
      if (this.modal.accion == "Editar") {
        this.users = [];
        if (this.objCaso.casos_soporte_users.length > 0) {
          this.objCaso.casos_soporte_users.forEach((user) => {
            this.users.push({
              id: user.id,
              name: user.name,
            });
          });
          this.objCaso.user = null;
        } else {
          this.users = [];
        }
      } else {
        this.users = {
          id: null,
          name: null,
        };
        this.objCaso.user = null;
        this.objCaso.correo = null;
      }
    },

    save() {
      this.objCaso.users = this.users;
      if (this.objCaso.respuesta) {
        this.objCaso.fecha_respuesta = moment().format("YYYY-MM-DD HH:mm:ss");
        this.tiempo.solicitado = moment(this.objCaso.fecha_solicitado);
        this.tiempo.respuesta = moment();
        if (this.objCaso.fecha_respuesta && this.objCaso.fecha_solicitado) {
          this.objCaso.tiempo = this.tiempo.respuesta.diff(
            this.tiempo.solicitado,
            "m"
          );
        }
        if (this.objCaso.estado == 1 || this.objCaso.estado == 3) {
          this.objCaso.estado = 2;
        } else if (this.objCaso.estado == 6) {
          this.objCaso.estado = 7;
        }
      }
      /* let datos = {
        objCaso: this.objCaso,
        users: this.users,
      }; */
      axios
        .post("api/admin/casosSoporte", this.objCaso)
        .then((response) => {
          this.$refs.closeModal1.click();
          this.$refs.closeModal2.click();
          this.objCaso = {};
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vualeva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async abrirGrupo(idCaso) {
      this.limpiarGrupo(idCaso);
    },

    saveGrupo() {
      this.cargando = true;
      axios
        .put("api/admin/casosSoporteAutorizaciones", this.form)
        .then((response) => {
          this.$refs.closeModalGrupo.click();
          this.getIndex();
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vualeva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async show(idCasoSoporte) {
      await axios
        .get("/api/admin/casosSoporte/show/" + idCasoSoporte)
        .then((response) => {
          this.objCaso = response.data;
          this.users = this.objCaso.casos_soporte_users;
        });
    },

    obtenerArchivo(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.adjunto = file;
          this.cargarArchivo(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.adjunto = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarArchivo(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveArchivo() {
      /* this.cargando = true; */
      let formData = new FormData();
      formData.append("link_archivo", this.adjunto);
      axios
        .post("api/admin/casosSoporte/archivo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.objCaso.link_archivo = response.data.link_archivo;
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroyArchivo() {
      this.$swal({
        title: "Esta seguro de eliminar este archivo?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/admin/casosSoporte/destroyFile", this.objCaso)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "El archivo se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.objCaso.link_archivo = null;
        }
      });
    },

    destroy(idCaso) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/casosSoporte/" + idCaso).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    casoDesarrollo(idCaso) {
      this.$swal({
        title: "Está seguro que desea escalar el caso a desarrollo?",
        text: "Asignar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, escalar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .get("/api/admin/casosSoporte/enviarCorreoDesarrollo", {
              params: {
                id: idCaso,
              },
            })
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se envió el correo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    verPDF(caso_id) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/admin/casosSoporte/verPdf/" + caso_id,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    chageTipoCaso() {
      this.objCaso.user = null;
      this.chk_usuario = null;
      if (this.objCaso.tipo_caso == 1) {
        //this.objCaso.user = this.user;
        this.objCaso.user_id = this.user;
      }
    },

    limpiarModalExport(reporte) {
      switch (reporte) {
        case "CasosSoporteExport":
          this.$refs.casosSoporteExport.limpiarModal();
          break;

        default:
          break;
      }
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getUsuarios();
    await this.getLineasNegocio();
    await this.getAsunto();
    await this.getViaComunicacion();
    await this.getEstado();
    await this.getUser();
    await this.buscarGrupo();
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
